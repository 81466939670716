<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Privacy Policy</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Privacy Policy</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="privacy-policy-area ptb-100">
  <app-PrivacyContent></app-PrivacyContent>
</div>

<app-footer></app-footer>
