import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-lms-banner',
  templateUrl: './lms-banner.component.html',
  styleUrls: ['./lms-banner.component.scss'],
})
export class LMSBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
