<div id="tech" class="container">
  <div class="section-title">
    <span class="sub-title">CORE PRACTICES</span>
    <h2>All our Apps follow these Core Practices</h2>
  </div>
  <div class="row justify-content-center">
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/helm.png"
            alt="kubernetes"
          />
        </div>
        <h3>Kubernetes Cluster</h3>
        <p>
          For automated deployments, scaling, self-healing and management of
          containerized solutions.
        </p>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg2">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/devops.png"
            alt="kubernetes"
          />
        </div>
        <h3>DevOps CI/CD</h3>
        <p>
          Automated builds and deployments provide immediate feedback on code
          changes and smoother releases.
        </p>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg3">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/cloud-computing.png"
            alt="kubernetes"
          />
        </div>
        <h3>Cloud Infrastructure</h3>
        <p>
          With the use of declaritve infrastructure, we can quickly scale and
          adjust resources based on demand.
        </p>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg4">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/scale.png"
            alt="kubernetes"
          />
        </div>
        <h3>Scalable Architecture</h3>
        <p>
          Designed to support high-performance applications, increasing
          workloads and evolving demands.
        </p>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg5">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/firebase.png"
            alt="kubernetes"
          />
        </div>
        <h3>Firebase Integration</h3>
        <p>
          App Analytics, Cloud Messaging, Function Apps, all already implemented
          and fully tested.
        </p>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="single-features-item">
        <div class="icon bg6">
          <img
            style="height: 40px; margin: 10px"
            src="assets/img/icons/cross-platform.png"
            alt="kubernetes"
          />
        </div>
        <h3>Cross Platform Support</h3>
        <p>
          All our apps can run on all platforms! Whether Mac OS, UWP, iOS or
          Android, we've got you covered.
        </p>
      </div>
    </div>
  </div>
</div>
