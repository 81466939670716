<div class="footer-area">
  <div class="container">
    <div class="footer-content">
      <a routerLink="/" class="logo">
        <img src="assets/img/logo1.png" alt="logo" style="max-height: 150px" />
      </a>
      <ul class="social-links">
        <li>
          <a href="mailto:admin@appworldsa.com" target="_blank"
            ><i class="ri-mail-fill"></i
          ></a>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B27662021775"
            target="_blank"
            ><i class="ri-whatsapp-fill"></i
          ></a>
        </li>
        <li>
          <a href="https://t.me/appworldsa" target="_blank"
            ><i class="ri-telegram-fill"></i
          ></a>
        </li>
      </ul>
      <p class="mt-2">
        If you would like to know more, feel free to contract us via
        <a href="mailto:admin@appworldsa.com" class="text-white">Email</a>,
        <a
          href="https://api.whatsapp.com/send/?phone=%2B27662021775"
          class="text-white"
          >WhatsApp</a
        >
        or <a href="https://t.me/appworldsa" class="text-white">Telegram</a>.
      </p>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a routerLink="/" class="nav-link">Home</a>
        </li>
        <li class="nav-item">
          <a routerLink="/how-to" class="nav-link">How it Works</a>
        </li>
        <li class="nav-item">
          <a routerLink="/terms-of-service" class="nav-link"
            >Terms of Service</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/privacy-policy" class="nav-link">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a routerLink="/faq" class="nav-link">FAQ's</a>
        </li>
        <li class="nav-item">
          <a routerLink="/contact-us" class="nav-link">Contact</a>
        </li>
      </ul>
      <p class="copyright">© AppWorld {{ currentYear }}</p>
    </div>
  </div>
</div>
