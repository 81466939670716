<div class="container">
  <div class="app-download-inner">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="app-download-content">
          <span class="sub-title">DOWNLOAD APP</span>
          <h2>Production Ready SaaS Software Startup Projects</h2>
          <p>
            Enjoy seamless, automated deployment and continuous integration that
            keeps your applications up-to-date, secure, and error-free.
          </p>
          <h6 style="margin-top: 20px; font-weight: 600; color: white">
            Try out App:
          </h6>
          <div class="btn-box">
            <a
              href="https://play.google.com/store/apps/details?id=com.appworldsa.podcast.demo"
              class="playstore-btn"
              target="_blank"
            >
              <img src="assets/img/play-store.png" alt="image" />
              Get It On
              <span>Google Play</span>
            </a>
            <a
              href="https://testflight.apple.com/join/11pPJSsN"
              class="applestore-btn"
              target="_blank"
            >
              <img src="assets/img/apple-store.png" alt="image" />
              Download on the
              <span>Apple Store</span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="new-app-main-banner-wrap-image">
          <img src="assets/img/apps/banner.png" alt="image" />
          <div class="wrap-image-shape-1">
            <img src="assets/img/more-home/banner/shape-3.png" alt="image" />
          </div>
          <div class="wrap-image-shape-2">
            <img src="assets/img/more-home/banner/shape-4.png" alt="image" />
          </div>
          <div class="banner-circle">
            <img
              src="assets/img/more-home/banner/banner-circle.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="shape5">
      <img src="assets/img/shape/shape4.png" alt="shape4" />
    </div>
    <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</div>
