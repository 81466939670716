<div
  style="
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
  "
>
  <video
    style="min-width: 100%; min-height: 100%; opacity: 4%"
    autoplay
    loop
    muted
  >
    <source src="assets/video.mp4" type="video/mp4" />
  </video>
</div>

<app-navbar></app-navbar>

<app-home-banner></app-home-banner>

<how-it-works></how-it-works>

<app-home-video></app-home-video>

<app-demos></app-demos>

<div class="features-area ptb-100 bg-F7F7FF">
  <app-features></app-features>
</div>

<div class="partner-area ptb-100">
  <div class="container">
    <div class="partner-title">Most commonly used technology stacks:</div>
    <app-framework></app-framework>
  </div>
</div>

<!-- <app-homeeight-features></app-homeeight-features> -->

<!-- <app-homeeight-about></app-homeeight-about> -->

<!-- <app-features-style-seven></app-features-style-seven> -->

<!-- <app-homeeight-screenshots></app-homeeight-screenshots> -->

<!-- <app-homeeight-intro></app-homeeight-intro> -->

<!-- <app-funfacts-style-three></app-funfacts-style-three> -->

<!-- <app-download-app-style-five></app-download-app-style-five> -->

<!-- <div class="feedback-wrap-area ptb-100">
    <app-feedback-style-two></app-feedback-style-two>
</div> -->

<!-- <app-pricing-style-seven></app-pricing-style-seven> -->

<!-- <app-homeeight-blog></app-homeeight-blog> -->

<!-- <div class="partner-area pb-100">
    <div class="container">
        <div class="partner-title">
            Trusted by world famous companies:
        </div>
        <app-partner></app-partner>
    </div>
</div>  -->

<app-footer></app-footer>
