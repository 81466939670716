<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Contact Us</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>Contact Us</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="contact-area ptb-100">
  <div class="container">
    <div class="section-title" style="margin-bottom: 20px">
      <h2>Get in Touch</h2>
      <p>
        We're here to help you take your SaaS ideas from concept to reality.
        Whether you're looking for consulting services, project support, or a
        production-ready app, our team is ready to assist.
      </p>
      <p>
        <strong>
          Fill out the form below and we'll get back to you as soon as
          possible.</strong
        >
      </p>
    </div>
    <div class="contact-form">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="space-y-3 text-left">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form__group">
                <div class="relative">
                  <div class="form-group">
                    <input
                      type="text"
                      id="name"
                      [ngClass]="{
                        'is__invalid-input': submitted && f['name'].errors
                      }"
                      class="peer block dark:bg-gray-700"
                      formControlName="name"
                      class="form-control"
                      placeholder="Name. Eg: John Doe"
                    />
                    <div
                      *ngIf="submitted && f['name'].errors"
                      class="is__invalid-error text-sm text-red-600"
                      style="text-align: left; color: #ed2775"
                    >
                      <div *ngIf="f['name'].errors['required']">
                        Required field *
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form__group">
                <div class="relative">
                  <div class="form-group">
                    <input
                      type="text"
                      id="email"
                      [ngClass]="{
                        'is__invalid-input': submitted && f['email'].errors
                      }"
                      class="peer block dark:bg-gray-700"
                      formControlName="email"
                      class="form-control"
                      placeholder="Enter your email address"
                    />
                    <div
                      *ngIf="submitted && f['email'].errors"
                      class="is__invalid-error text-sm text-red-600"
                      style="text-align: left; color: #ed2775"
                    >
                      <div *ngIf="f['email'].errors['required']">
                        Required field
                      </div>
                      <div *ngIf="f['email'].errors['email']">
                        Email must be an email address valid
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form__group">
                <div class="relative">
                  <div class="form-group">
                    <select
                      type="text"
                      id="subject"
                      [ngClass]="{
                        'is__invalid-input': submitted && f['subject'].errors
                      }"
                      class="peer block dark:bg-gray-700"
                      formControlName="subject"
                      class="form-control"
                    >
                      <option value="" selected>
                        Please select best option...
                      </option>
                      <option *ngFor="let o of options">
                        {{ o.name }}
                      </option>
                    </select>

                    <div
                      *ngIf="submitted && f['subject'].errors"
                      class="is__invalid-error text-sm text-red-600"
                      style="text-align: left; color: #ed2775"
                    >
                      <div *ngIf="f['subject'].errors['required']">
                        Required field *
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form__group">
                <div class="relative">
                  <div class="form-group">
                    <textarea
                      type="text"
                      id="message"
                      [ngClass]="{
                        'is__invalid-input': submitted && f['message'].errors
                      }"
                      class="peer block dark:bg-gray-700"
                      formControlName="message"
                      class="form-control"
                      placeholder="Please describe why you are interested in any of our service offerings"
                      rows="5"
                    ></textarea>
                    <div
                      *ngIf="submitted && f['message'].errors"
                      class="is__invalid-error text-sm text-red-600"
                      style="text-align: left; color: #ed2775"
                    >
                      <div *ngIf="f['message'].errors['required']">
                        Required field *
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="mt-5">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <button [disabled]="saving" type="submit" class="default-btn">
              <i class="bx bx-paper-plane"></i> Send Message
            </button>
            <div
              class="is__invalid-error text-sm text-red-600 mt-2"
              style="text-align: center; color: #ed2775"
            >
              <div *ngIf="submitSuccess">Message Sent!</div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>
