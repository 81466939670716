import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-features',
  templateUrl: './medical-features.component.html',
  styleUrls: ['./medical-features.component.scss'],
})
export class MedicalFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
