import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-features',
  templateUrl: './hotel-features.component.html',
  styleUrls: ['./hotel-features.component.scss'],
})
export class HotelFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
