import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nft-video',
  templateUrl: './nft-video.component.html',
  styleUrls: ['./nft-video.component.scss'],
})
export class NFTVideoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
