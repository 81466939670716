<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>FAQ</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="faq-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="faq-sidebar">
          <ul>
            <li>
              <a routerLink="/faq"><span>About Our Services</span></a>
            </li>
            <li>
              <a routerLink="/faq-2" class="active"
                ><span>Help with Apps</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="faq-accordion accordion">
          <div class="accordion">
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(0)"
                [ngClass]="{ open: isSectionOpen(0) }"
              >
                How to delete my account?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(0) }"
              >
                <p>
                  You can request manual account deletion by sending an email to
                  <a href="mailto:support@appworldsa.com" target="_blank"
                    >support&#64;appworldsa.com</a
                  >
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(1)"
                [ngClass]="{ open: isSectionOpen(1) }"
              >
                What information is collected?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(1) }"
              >
                <p>
                  We only collect information for the purpose of enchancing user
                  app experience. Your information will not be shared with any
                  other party. To learn more about how we use your information
                  please see our
                  <a routerLink="/privacy-policy">privacy policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
