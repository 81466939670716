<app-navbar-dark></app-navbar-dark>

<app-hotel-banner></app-hotel-banner>

<app-hotel-features></app-hotel-features>

<app-hotel-screenshots></app-hotel-screenshots>

<app-hotel-video></app-hotel-video>

<app-footer></app-footer>
