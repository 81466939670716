<app-navbar-dark></app-navbar-dark>

<app-shop-banner></app-shop-banner>

<app-shop-features></app-shop-features>

<app-shop-screenshots></app-shop-screenshots>

<app-shop-video></app-shop-video>

<app-footer></app-footer>
