<app-navbar-dark></app-navbar-dark>

<app-nft-banner></app-nft-banner>

<app-nft-features></app-nft-features>

<app-nft-screenshots></app-nft-screenshots>

<app-nft-video></app-nft-video>

<app-footer></app-footer>
