<app-navbar-dark></app-navbar-dark>

<app-emergency-banner></app-emergency-banner>

<app-emergency-features></app-emergency-features>

<app-emergency-screenshots></app-emergency-screenshots>

<app-emergency-video></app-emergency-video>

<app-footer></app-footer>
