import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.scss'],
})
export class HowToComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scroll(elementId: string) {
    var element = document.getElementById(elementId) as HTMLElement;
    element.scrollIntoView();
  }
}
