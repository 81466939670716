<app-navbar-dark></app-navbar-dark>

<app-medical-banner></app-medical-banner>

<app-medical-features></app-medical-features>

<app-medical-screenshots></app-medical-screenshots>

<app-medical-video></app-medical-video>

<app-footer></app-footer>
