<div class="partner-slides">
  <owl-carousel-o [options]="partnerSlides">
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/terraform-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/abp-logo.png" alt="image" />
        </a>
      </div>
    </ng-template> -->
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/angular-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/docker-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/firebase-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/flutter-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/kubernetes-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/azure-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/unity-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="partner-item">
        <a href="#" class="d-block">
          <img src="assets/img/frameworks/sql-logo.png" alt="image" />
        </a>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
