import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-podcast-video',
  templateUrl: './podcast-video.component.html',
  styleUrls: ['./podcast-video.component.scss'],
})
export class PodcastVideoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
