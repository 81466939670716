<div class="app-light-0-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="sub-title">APP SCREENS</span>
      <h2>Light and Dark Theme Screenshots</h2>
    </div>
    <div class="app-light-0-slides" style="margin-bottom: 40px">
      <owl-carousel-o [options]="screenshotsSlides">
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-03.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-04.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-05.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-06.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-07.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-08.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-01.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/light-02.png" alt="light-0" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="app-light-0-slides">
      <owl-carousel-o [options]="screenshotsSlides">
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-03.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-04.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-05.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-06.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-07.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-08.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-01.png" alt="light-0" />
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-screenshot-card">
            <img src="assets/img/apps/hotel/dark-02.png" alt="light-0" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
