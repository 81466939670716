import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-TermsContent',
  templateUrl: './terms-content.component.html',
  styleUrls: ['./terms-content.component.scss'],
})
export class TermsContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scroll(elementId: string) {
    var element = document.getElementById(elementId) as HTMLElement;
    element.scrollIntoView();
  }
}
