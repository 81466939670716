import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-features',
  templateUrl: './wallet-features.component.html',
  styleUrls: ['./wallet-features.component.scss'],
})
export class WalletFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
