import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-podcast-banner',
  templateUrl: './podcast-banner.component.html',
  styleUrls: ['./podcast-banner.component.scss'],
})
export class PodcastBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
