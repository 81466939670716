<div class="video-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="intro-video-content">
          <span class="sub-title">ABOUT VIDEO</span>
          <h2>Watch Our AppWorld SaaS Demo</h2>
          <p>
            See how easy it is to create your own SaaS App. In this demo we
            showcase some of our features like how we create an app from the
            marketplace, deploy the app and customize content.
          </p>
          <p>
            In most cases, new features are built over to support a specific
            app. With AppWorld Marketplace we have the ability to overlap and
            share new features across apps.
          </p>
          <a
            target="_blank"
            routerLink="assets/img/apps/appworld.pdf"
            class="default-btn"
            >See Slideshow</a
          >
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="intro-video-box">
          <img src="assets/img/apps/video-img1.jpg" alt="video-img" />
          <button class="video-btn popup-video" (click)="openPopup()">
            <i class="ri-play-line"></i>
          </button>
          <div class="shape">
            <img
              src="assets/img/shape/shape13.png"
              class="shape10"
              alt="image"
            />
            <img
              src="assets/img/shape/shape14.png"
              class="shape11"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <iframe
      src="https://www.youtube.com/embed/YdMn3pwEb-c"
      title="AppWorld SaaS Apps"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
    <button (click)="closePopup()" type="button" class="close-btn">
      <i class="ri-close-line"></i>
    </button>
  </div>
</div>
