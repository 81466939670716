import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emergency-features',
  templateUrl: './emergency-features.component.html',
  styleUrls: ['./emergency-features.component.scss'],
})
export class EmergencyFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
