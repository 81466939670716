<div class="row">
  <div class="col-sm-12 btn btn-primary">Angular Sweetalert Demo</div>
</div>
<div style="padding: 5px; margin: 5px">
  <button
    class="btn btn-info"
    style="margin-right: 10px; margin-left: 10px"
    (click)="simpleAlert()"
  >
    Simple Alert
  </button>
  <button
    class="btn btn-success"
    style="margin-right: 10px; margin-left: 10px"
    (click)="alertWithSuccess()"
  >
    Alert with Success
  </button>
  <button
    class="btn btn-primary"
    style="margin-right: 10px; margin-left: 10px"
    (click)="confirmBox()"
  >
    Confirm Box
  </button>
  <button
    class="btn btn-danger"
    style="margin-right: 10px; margin-left: 10px"
    (click)="erroalert()"
  >
    Error Alert
  </button>
  <button
    class="btn btn-warning"
    style="margin-right: 10px; margin-left: 10px"
    (click)="topend()"
  >
    Top End
  </button>
</div>
