import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-banner',
  templateUrl: './medical-banner.component.html',
  styleUrls: ['./medical-banner.component.scss'],
})
export class MedicalBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
