import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

interface Article {
  id: number;
  title: string;
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  saving = false;
  submitSuccess = false;
  options = [
    {
      name: 'Consulting Services: Get expert advice for your SaaS business needs.',
      value: 1,
    },
    {
      name: "App Support: Benefit from our hands-on assistance to ensure your SaaS project's success.",
      value: 2,
    },
    {
      name: 'Custom Marketplace App: Let us build your custom SaaS application.',
      value: 3,
    },
    {
      name: 'Proof of Concept (POC): Validate your SaaS ideas with our RAD process.',
      value: 4,
    },
    { name: 'Other', value: 5 },
  ];

  constructor(
    private readonly _formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    const { name, email, subject, message, duration } = this.form.value;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.sendMessage();
  }

  sendMessage() {
    if (this.saving == false && this.submitSuccess == false) {
      this.saving = true;
      const body = {
        name: this.form.value['name'],
        email: this.form.value['email'],
        subject: this.form.value['subject'],
        message: this.form.value['message'],
      };
      this.http
        .post<any>('https://host.appworldsa.com/api/Root/comms/contact', body)
        .subscribe(
          (data) => {
            this.submitSuccess = true;
            this.saving = false;
            Swal.fire(
              'Message Sent',
              'We will get back to you shortly!',
              'success'
            );
          },
          (error) => {
            this.saving = false;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
            });
          }
        );
    }
  }
}
