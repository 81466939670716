<div class="key-features-area bg-transparent-with-color pt-100 pb-100">
  <div class="container">
    <div class="section-title">
      <span class="sub-title">KEY FEATURES</span>
      <h2>Functionality already available on this app</h2>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card style-two">
          <div class="icon">
            <i class="ri-shield-line"></i>
          </div>
          <h3>Fully Secured</h3>
          <p>
            Working sign in an sign up flows integrated with an auth guard on
            app views.
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card bg-color-two style-two">
          <div class="icon bg2">
            <i class="ri-google-line"></i>
          </div>
          <h3>External Logins</h3>
          <p>
            Google and Apple iOS external login integrated with our own backend
            Identity Provider.
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card style-two">
          <div class="icon">
            <i class="ri-chat-voice-line"></i>
          </div>
          <h3>SignalR Messaging</h3>
          <p>
            Realtime event and app messaging notifications integrated through
            SignalR.
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card bg-color-two style-two">
          <div class="icon bg2">
            <i class="ri-discuss-line"></i>
          </div>
          <h3>Push Notifications</h3>
          <p>
            Nudge engagements through Firebase Messaging or support SignalR
            notifications with Pushed Notifications.
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card style-two">
          <div class="icon">
            <i class="ri-t-shirt-line"></i>
          </div>
          <h3>Light/Dark Theme</h3>
          <p>
            Keep users engaged by allowing each user to customize the look of
            the UI to their preference.
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="cursor: pointer">
        <div class="key-features-card bg-color-two style-two">
          <div class="icon bg2">
            <i class="ri-macbook-line"></i>
          </div>
          <h3>Responsive Ready</h3>
          <p>
            Responsive design enables users to have positive experiences on
            mobile or desktop.
          </p>
        </div>
      </div>
    </div>
    <div class="key-features-btn">
      <a routerLink="/contact-us" class="default-btn">Get In Touch</a>
    </div>
  </div>
</div>
