<app-navbar-light></app-navbar-light>

<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>FAQ</li>
      </ul>
    </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
  <div class="banner-shape1">
    <img src="assets/img/shape/shape9.png" alt="image" />
  </div>
</div>

<div class="faq-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="faq-sidebar">
          <ul>
            <li>
              <a routerLink="/faq" class="active"
                ><span>About Our Services</span></a
              >
            </li>
            <li>
              <a routerLink="/faq-2"><span>Help with Apps</span></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="faq-accordion accordion">
          <div class="accordion">
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(0)"
                [ngClass]="{ open: isSectionOpen(0) }"
              >
                Why are our consultanting services important?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(0) }"
              >
                <p>
                  Our consulting services are designed to provide expert
                  guidance and support to help you maximize the potential of
                  your SaaS projects. We offer insights into best practices,
                  help identify potential pitfalls, and provide strategies to
                  ensure your project is successful from start to finish.
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(1)"
                [ngClass]="{ open: isSectionOpen(1) }"
              >
                What is the purpose of hiring us for your next project?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(1) }"
              >
                <p>
                  Hiring us for your next project means leveraging our extensive
                  experience and expertise in SaaS development. We bring a
                  wealth of knowledge in building scalable, efficient, and
                  user-friendly applications. Our team is dedicated to
                  delivering high-quality solutions tailored to meet your
                  specific business needs.
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(2)"
                [ngClass]="{ open: isSectionOpen(2) }"
              >
                What makes us a suitable fit for your business needs?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(2) }"
              >
                <p>
                  We understand that each business has unique requirements. Our
                  team excels at understanding these needs and crafting custom
                  solutions that align with your goals. Our approach is
                  collaborative, ensuring that we work closely with you to
                  deliver results that exceed your expectations.
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(3)"
                [ngClass]="{ open: isSectionOpen(3) }"
              >
                What support is required for a production ready app?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(3) }"
              >
                <p>
                  Support for a production-ready app includes comprehensive
                  testing, performance optimization, security measures, and
                  ongoing maintenance. We ensure that your app is fully
                  functional, secure, and able to handle your expected user
                  load. Our team is here to provide continuous support and
                  updates as needed.
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(4)"
                [ngClass]="{ open: isSectionOpen(4) }"
              >
                Is costs of hosting inclusive as part of a new project?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(4) }"
              >
                <p>
                  The costs of hosting are not typically included as part of a
                  new project. Hosting costs vary based on the specific
                  requirements of your application, such as server capacity,
                  bandwidth, and additional services. We can provide guidance on
                  the best hosting options for your needs and help you estimate
                  the associated costs.
                </p>
              </div>
            </div>
            <div class="accordion-section">
              <div
                class="accordion-header"
                (click)="toggleSection(5)"
                [ngClass]="{ open: isSectionOpen(5) }"
              >
                Do we create POC's as part of our service offering?
              </div>
              <div
                class="accordion-content"
                [ngClass]="{ open: isSectionOpen(5) }"
              >
                <p>
                  Yes, we offer the creation of Proof of Concepts (POCs) as part
                  of our service offering. POCs are a valuable way to validate
                  ideas and ensure feasibility before committing to full-scale
                  development. This helps you make informed decisions and
                  reduces risks associated with your project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
