import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.scss'],
})
export class MedicalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
