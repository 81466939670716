<app-navbar-light></app-navbar-light>

<div class="error-area pb-100">
  <div class="container">
    <div class="error-content">
      <img src="assets/img/error.png" alt="image" />
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <a routerLink="/" class="default-btn">Back To Home</a>
    </div>
  </div>
</div>

<app-footer></app-footer>
