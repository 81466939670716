import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-banner',
  templateUrl: './shop-banner.component.html',
  styleUrls: ['./shop-banner.component.scss'],
})
export class ShopBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
