import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-emergency-banner',
  templateUrl: './emergency-banner.component.html',
  styleUrls: ['./emergency-banner.component.scss'],
})
export class EmergencyBannerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
