<app-navbar-dark></app-navbar-dark>

<app-lms-banner></app-lms-banner>

<app-lms-features></app-lms-features>

<app-lms-screenshots></app-lms-screenshots>

<app-lms-video></app-lms-video>

<app-footer></app-footer>
