<app-navbar-dark></app-navbar-dark>

<app-wallet-banner></app-wallet-banner>

<app-wallet-features></app-wallet-features>

<app-wallet-screenshots></app-wallet-screenshots>

<app-wallet-video></app-wallet-video>

<app-footer></app-footer>
